import "vite/modulepreload-polyfill";
/* import '../css/app.css';*/
import "./bootstrap";
import "./lenis";
import "./validate";

import VueSocialSharing from "vue-social-sharing";
import Dialog from "./modules/Dialog/";
import Notify from "./modules/Notify/";
import { createApp } from "vue";

const app = createApp({
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        window.lenis.scrollTo(this.getAttribute("href"), { offset: -160 });
      });
    });
  },
  methods: {
    handleScroll() {
      // when the user scrolls, check the pageYOffset
      if (window.pageYOffset > 0) {
        document.getElementsByTagName("html")[0].classList.add("scrolled");
      } else {
        document.getElementsByTagName("html")[0].classList.remove("scrolled");
      }
    },
  },
});

app.config.globalProperties.$filters = {
  prettyBytes(num) {
    if (typeof num !== "number" || isNaN(num)) {
      throw new TypeError("Expected a number");
    }
    const neg = num < 0;
    const units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    if (neg) {
      num = -num;
    }

    if (num < 1) {
      return (neg ? "-" : "") + num + " B";
    }

    const exponent = Math.min(
      Math.floor(Math.log(num) / Math.log(1000)),
      units.length - 1,
    );
    num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
    const unit = units[exponent];
    return (neg ? "-" : "") + num + " " + unit;
  },
};

app.use(Notify);
app.use(Dialog);
app.use(VueSocialSharing);

Object.entries(
  import.meta.glob("./components/**/*.vue", { eager: true }),
).forEach(([path, definition]) => {
  app.component(
    path
      .split("/")
      .pop()
      .replace(/\.\w+$/, ""),
    definition.default,
  );
});

app.config.globalProperties.$menu = null;

// Blocks
/*
  const registerBlockComponent = (name, component) => {
   return app.component(name, component)
  }

  if (typeof window.DotCMS.blocks !== 'undefined') {
    window.DotCMS.blocks.map(componentName => {

      return registerBlockComponent(componentName.type, {
        template: '#' + componentName.type,
        props: {
          ...componentName.props,
          form: {
            type: Object,
            default: () => {}
          }
        },
        computed: {
            availableBlocks() {
                return window.DotCMS.blocks
            }
        },
      })
    })
  }
  */

//  app.use(dialog)

app.mount("#app");
