import Tempus from '@studio-freight/tempus'
import Lenis from '@studio-freight/lenis'

const lenis = new Lenis({
    duration: 1.2,
    easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
    orientation: 'vertical', // vertical, horizontal
    gestureOrientation: 'vertical', // vertical, horizontal, both
    smoothWheel: true,
    wheelMultiplier: 1,
    smoothTouch: false,
    touchMultiplier: 2,
    infinite: false,
})

// lenis.on('scroll', ScrollTrigger.update)

// // get scroll value
/*
 lenis.on('scroll', ({ scroll, limit, velocity, direction, progress }) => {
   console.log({ scroll, limit, velocity, direction, progress })
 })
*/

 if (typeof window !== 'undefined') {
  // gsap.defaults({ ease: 'none' })
  // gsap.registerPlugin(ScrollTrigger)
 //  ScrollTrigger.defaults({ markers: process.env.NODE_ENV === 'development' })

   // merge rafs
  // gsap.ticker.lagSmoothing(0)
  // gsap.ticker.remove(gsap.updateRoot)
   Tempus?.add((time) => {
  //   gsap.updateRoot(time / 1000)
     lenis.raf(time)
   }, 0)

   // reset scroll position
   window.scrollTo(0, 0)
   window.history.scrollRestoration = 'manual'

   window.lenis = lenis
 }
